'use strict'

const _ = require('lodash')
const menuFixer = require('../plugins/menuFixer')

let bmiCnt = 0 //eslint-disable-line santa/no-module-state
let pglkCnt = 0 //eslint-disable-line santa/no-module-state

const BMI = 'bmi'
const PGLK = 'pglk'

function convertOldMenuToNewMenu(rawData, siteData) {
    return _.map(rawData, function (data) {
        return saveMenuItem(siteData, data)
    })
}

function saveMenuItem(siteData, data) {
    const id = BMI + bmiCnt++
    const pageData = siteData[sanitizeId(data.refId)]
    siteData[id] = {
        id,
        label: pageData.title,
        isVisible: !pageData.hidePage,
        isVisibleMobile: pageData.mobileHidePage !== undefined ? !pageData.mobileHidePage : !pageData.hidePage,
        items: convertOldMenuToNewMenu(data.items, siteData),
        link: saveLinkItem(siteData, data.refId),
        type: 'BasicMenuItem',
        metaData: {}
    }

    return `#${id}`
}

function saveLinkItem(siteData, refId) {
    const id = PGLK + pglkCnt++

    siteData[id] = {
        id,
        type: 'PageLink',
        pageId: refId,
        metaData: {}
    }


    return `#${id}`
}

function getDataItem(documentData, id) {
    return id && documentData[sanitizeId(id)]
}

function setAsMenuHeader(basicMenuItem) {
    delete basicMenuItem.link
}

function getPageLink(documentData, basicMenuItem) {
    const linkItem = getDataItem(documentData, basicMenuItem.link)
    return _.get(linkItem, 'type') === 'PageLink' ? linkItem : undefined
}

function isInvalidPageLink(documentData, basicMenuItem) {
    const pageLink = getPageLink(documentData, basicMenuItem)
    return pageLink && !getDataItem(documentData, pageLink.pageId)
}

function isInvalidLink(documentData, basicMenuItem) {
    return basicMenuItem.link && (basicMenuItem.link === '#CUSTOM_MENU_HEADER' || isInvalidPageLink(documentData, basicMenuItem))
}

function convertMenuItemsOfNonExistingPagesToHeaders(documentData, dataItem) {
    dataItem.items = _.filter(dataItem.items, function (basicMenuItemId) {
        return !_.isUndefined(getDataItem(documentData, basicMenuItemId))
    })

    _.forEach(dataItem.items, function (basicMenuItemId) {
        const basicMenuItem = getDataItem(documentData, basicMenuItemId)
        if (isInvalidLink(documentData, basicMenuItem)) {
            setAsMenuHeader(basicMenuItem)
        }
        convertMenuItemsOfNonExistingPagesToHeaders(documentData, basicMenuItem)
    })
}

function removeMenuDataItems(pageJson) {
    _(pageJson.data.document_data)
        .filter({type: 'Menu'})
        .map('id')
        .forEach(function (pageId) {
            delete pageJson.data.document_data[pageId]
        })
}

function isJsonForPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Page'
}

function sanitizeId(str) {
    return _.replace(str, '#', '')
}

function addMissingPageIdsToMenu(docData, pageIdsInSite) {
    const customMenuItems = docData.CUSTOM_MAIN_MENU.items
    const pageIdsInMenu = _(docData)
        .filter({type: 'BasicMenuItem'})
        .map('link')
        .compact()
        .map(sanitizeId)
        .map(function (linkItemId) {
            return docData[linkItemId]
        })
        .map('pageId')
        .compact()
        .map(sanitizeId)
        .value()

    const pagesMissingFromMenu = _.difference(pageIdsInSite, pageIdsInMenu)
    _.forEach(pagesMissingFromMenu, function (missingPageId) {
        if (docData[missingPageId].isPopup) {
            return
        }
        customMenuItems.push(createMenuItem(docData, missingPageId))
    })
}

function createMenuItem(siteData, pageId) {
    const id = BMI + bmiCnt++
    const pageData = siteData[pageId]
    siteData[id] = {
        id,
        label: pageData.title,
        isVisible: !pageData.hidePage,
        isVisibleMobile: pageData.mobileHidePage !== undefined ? !pageData.mobileHidePage : !pageData.hidePage,
        items: [],
        link: saveLinkItem(siteData, `#${pageId}`),
        type: 'BasicMenuItem',
        metaData: {}
    }

    return `#${id}`
}

function getInitDataIndexCounts(siteData, countId) {
    const indexedDataItems = _.filter(siteData, function (item) {
        return item.id && item.id.indexOf(countId) === 0
    })
    let maxBmiId = -1
    _.forEach(indexedDataItems, function (item) {
        const currentIndex = Number(item.id.replace(countId, ''))
        if (currentIndex > maxBmiId) {
            maxBmiId = currentIndex
        }
    })
    return ++maxBmiId
}

function menuWalker(pageJson, pageIdsArray) {
    function removeInvalidSubMenus(menuItem) {
        menuItem.items = _.filter(menuItem.items, isValidItem)
        _(menuItem.items)
            .map(itemId => getDataItem(pageJson.data.document_data, itemId))
            .forEach(removeInvalidSubMenus)
    }

    function isValidItem(menuItemId) {
        const masterPageData = pageJson.data.document_data
        const menuItem = getDataItem(masterPageData, menuItemId)
        const hasNoLink = () => !menuItem.link
        const linksToNonPage = () => !getPageLink(masterPageData, menuItem)
        const pointsToAnExistingPage = () => {
            const linkItem = getDataItem(masterPageData, menuItem.link)
            const linkedPageId = _.get(linkItem, 'pageId')
            return _.includes(pageIdsArray, sanitizeId(linkedPageId))
        }

        return hasNoLink() || linksToNonPage() || pointsToAnExistingPage()
    }

    return {removeInvalidSubMenues: removeInvalidSubMenus}
}

/**
 * @exports utils/dataFixer/plugins/customSiteMenuFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson, pageIdsArray) {
        if (isJsonForPage(pageJson)) {
            removeMenuDataItems(pageJson)
            return
        }
        menuFixer.exec(pageJson, pageIdsArray)

        const docData = pageJson.data.document_data
        bmiCnt = getInitDataIndexCounts(docData, BMI)
        pglkCnt = getInitDataIndexCounts(docData, PGLK)

        if (!pageJson.data.document_data.CUSTOM_MAIN_MENU) {
            pageJson.data.document_data.CUSTOM_MAIN_MENU = {
                id: 'CUSTOM_MAIN_MENU',
                items: convertOldMenuToNewMenu(pageJson.data.document_data.MAIN_MENU.items, pageJson.data.document_data),
                name: 'Custom Main Menu',
                type: 'CustomMenu',
                metaData: {}
            }
        }

        if (!pageJson.data.document_data.CUSTOM_MENUS) {
            pageJson.data.document_data.CUSTOM_MENUS = {
                id: 'CUSTOM_MENUS',
                menus: ['#CUSTOM_MAIN_MENU'],
                type: 'CustomMenusCollection',
                metaData: {}
            }
        } else if (!_.includes(pageJson.data.document_data.CUSTOM_MENUS.menus, '#CUSTOM_MAIN_MENU')) {
            pageJson.data.document_data.CUSTOM_MENUS.menus.push('#CUSTOM_MAIN_MENU')
        }
        addMissingPageIdsToMenu(docData, pageIdsArray)

        docData.CUSTOM_MAIN_MENU.items = _.uniq(docData.CUSTOM_MAIN_MENU.items) //fix for CLNT-8914 - ensure custom main menu items id uniqueness

        convertMenuItemsOfNonExistingPagesToHeaders(docData, docData.CUSTOM_MAIN_MENU)

        if (pageJson.data.document_data.MAIN_MENU.items.length > 0) {
            pageJson.data.document_data.MAIN_MENU.items = []
        }

        const menuItem = pageJson.data.document_data.CUSTOM_MAIN_MENU
        menuWalker(pageJson, pageIdsArray).removeInvalidSubMenues(menuItem)
    }
}
